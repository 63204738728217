import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createI18n } from 'vue-i18n'
import zh from '../locales/zh'

const app = createApp(App)

const messages = {
    zh
}

const i18n = createI18n({
    locale: 'zh',
    messages
})

app.use(ElementPlus)
app.use(router.router)
app.use(i18n)
app.mount('#app')
