import {createRouter,createWebHistory} from 'vue-router'

const routes = [
    {path: '/',component: ()=> import('./pages/Login.vue')},
    {path: '/Login',component: ()=> import('./pages/Login.vue')},
    {path: '/Register',component: ()=> import('./pages/Register.vue')},
    {path: '/BanAccount',component: ()=> import('./pages/BanAccount.vue')},
    {path: '/QRScan',component: ()=> import('./pages/QRScan.vue')},
    {path: '/ResetPassword',component: ()=> import('./pages/Resetpass.vue')},
    {path: '/Home',component: ()=> import('./pages/Home.vue')}
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default {router}