export default {
    "Welcome to Chatbot UI": "流云AI",
    "Login with your account to continue":"让你变得更专业的智能教练",
    "Log in":"登录",
    "Sign up": "注册",
    "Welcome back": "欢迎回来",
    "Account": "请输入用户名",
    "Password": "请输入密码",
    "Confirm Password": "再次输入密码",
    "captcha": "请输入图形验证码",
    "Forget password?": "忘记密码",
    "Need Regiest": "尊敬用户，您好，扫码登录需要绑定用户信息",
    "Warm Tips": "温馨提示",
    "Go Band": "去绑定",
    "Cancel": "取消",
    "Band to your account": "请绑定已注册账号",
    "Band": "绑定",
    "QR Scan": "二维码登录",
    "Login": "登录",
    "Don't have an account?": "还没有账号？",
    "Terms of use": "使用条款",
    "Privacy policy": "隐私政策",
    "Create your account": "创建账号",
    "Please note that phone verification is required for signup.": "请注意，注册需要邮箱验证。",
    "Your number will only be used to verify your identity": "出于安全目的您的邮箱将仅用于验证您的身份",
    "for security purposes.": "",
    "Email":"请输入邮箱",
    "VerifyCode":"请输入邮箱验证码",
    "Phone":"请输入手机号",
    "Create":"创建账户",
    "Input Username":"请输入用户名或邮箱或手机号",
    "Modify Password": "修改密码",
    "New Password": "请输入新密码",
    "Confirm New Password": "请再次输入新密码",
    "Confirm Modify": "确认修改"
}